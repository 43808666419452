import React from 'react';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import { ResourceSetProvider } from '../context/ResourceSetContext';
import { ThemeProvider } from 'styled-components';
import { theme } from '@sygnia/components';
import Main from '../modules/main';
import { mapper } from '../common/utils';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';

const SD_MAP = {
  title: ['structuredData', 'title'],
  image: ['image'],
  altText: ['structuredData', 'altText'],
  description: ['structuredData', 'description'],
  noindex: ['structuredData', 'noindex'],
  nofollow: ['structuredData', 'nofollow'],
};

const getLayout = guidePage => {
  const { pathname } = useLocation();
  const sd = mapper(guidePage, SD_MAP);
  sd.title = guidePage.headline;
  const isParent = !guidePage.parentReference;

  const shareLayout = [
    {
      __typename: LAYOUT_TYPES.SHARE,
      summary: sd,
      url: pathname,
      orientation: 'horizontal',
    },
  ];
  // const navigationIndexLayout = [
  //   {
  //     __typename: LAYOUT_TYPES.NAVIGATION_INDEX,
  //     items: isParent
  //       ? guidePage?.navigationIndex?.items
  //       : guidePage?.parentReference.navigationIndex?.items,
  //     wrapperTitle: isParent
  //       ? guidePage?.navigationIndex?.wrapperTitle
  //       : guidePage?.parentReference.navigationIndex?.wrapperTitle,
  //     isParent: isParent,
  //   },
  // ];

  let heroLayout = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      structuredData: sd,
    },
    {
      __typename: LAYOUT_TYPES.HERO_EDITORIAL,
      title: guidePage?.headline,
      description: guidePage?.description,
      image: guidePage?.heroImage,
      heroBGColor:
        guidePage?.color?.value || guidePage?.parentReference?.color?.value,
      parentReference: guidePage?.parentReference,
      category: guidePage?.category,
      summary: sd,
      orientation: 'horizontal',
      guides: isParent
        ? guidePage?.navigationIndex?.items
        : guidePage?.parentReference.navigationIndex?.items,
      slug: guidePage?.slug,
    },
  ];

  const contactBlock = [
    {
      __typename: LAYOUT_TYPES.CTA_CENTERED,
      footnote: guidePage.footer.footnote,
      title: guidePage.footer.title,
      description: guidePage.footer.description,
      image: guidePage.footer.image,
      meta: guidePage.footer.meta,
    },
  ];

  const indexLayout = [
    {
      __typename: LAYOUT_TYPES.INDEX,
      title: guidePage?.index?.title,
      links: guidePage?.index?.links,
    },
  ];

  //ToDo: Refactor this
  if (isParent)
    return [
      ...heroLayout,
      //...navigationIndexLayout,
      ...guidePage.layout,
      ...contactBlock,
    ];
  else
    return [
      ...heroLayout,
      ...guidePage.layout,
      //...navigationIndexLayout,
      ...contactBlock,
    ];
};

const GuidePageTemplate = props => {
  const guidePage = get(props, 'data.contentfulGuides');
  let layout = getLayout(guidePage);
  return (
    <ThemeProvider theme={theme}>
      <ResourceSetProvider>
        <Main {...props} page={guidePage}>
          <Layout layout={layout} />
        </Main>
      </ResourceSetProvider>
    </ThemeProvider>
  );
};

export default GuidePageTemplate;

export const pageQuery = graphql`
  query GuidesBySlug($slug: String!) {
    contentfulGuides(slug: { eq: $slug }) {
      contentful_id
      headline
      slug
      category {
        id
        title
      }
      slug
      color {
        value
      }
      footer {
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        links {
          id
          label
          type
          to
          external
          meta {
            internal {
              content
            }
          }
          file {
            title
            file {
              fileName
              url
            }
          }
        }
        footnote {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid {
            src
          }
        }
        meta {
          internal {
            content
          }
        }
      }
      structuredData {
        ...StructuredDataFields
      }
      image {
        fluid {
          src
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      parentReference {
        id
        slug
        headline
        category {
          id
          title
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        color {
          value
        }
        guides {
          slug
        }
        navigationIndex {
          wrapperTitle: title
          items: menuItems {
            slug
            headline
            author {
              title
            }
            color {
              value
            }
            image {
              fluid {
                src
              }
            }
          }
        }
      }
      navigationIndex {
        wrapperTitle: title
        items: menuItems {
          slug
          headline
          author {
            title
          }
          color {
            value
          }
          image {
            fluid {
              src
            }
          }
        }
      }
      heroImage: image {
        fluid(quality: 100, maxWidth: 992) {
          src
        }
      }
      layout {
        __typename
        ... on Node {
          ...BlockQuoteFields
          ...HighlightFields
          ...ContentFields
          ...CTACenteredFields
          ...SectionLayoutFields
          ...IndexFields
        }
      }
    }
  }
`;
